// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

// Initialize Firebase
export const firebaseApp = initializeApp({
  apiKey: "AIzaSyC5sBrJCixSc-he7OzFSDYGTbdeDIn-2AE",
  authDomain: "psyalgarve.firebaseapp.com",
  projectId: "psyalgarve",
  storageBucket: "psyalgarve.appspot.com",
  messagingSenderId: "1030089809907",
  appId: "1:1030089809907:web:ecd6e88586af758b833c86",
});

const functions = getFunctions();
export const sendEmail = httpsCallable(functions, "sendEmail");
