export default {
  header: {
    links: {
      services: "Services",
      about: "About",
      contact: "Contact",
    },
  },
  hero: {
    title: {
      text: "{0} is not a destination but a {1}.",
      bold: "Mental Health",
      teal: "process",
    },
    btn: {
      services: "Our services",
      contact: "Contact us",
    },
  },
  services: {
    title: "Our services",
    list: {
      clinicalPsychology: "Clinical Psychology",
      individualTherapy: "Individual Therapy",
      homeTherapy: "Home Therapy",
      onlineTherapy: "Online Therapy",
      psychotherapy: "Psychotherapy",
      mindfulness: "Mindfulness",
      vocationalPsychology: "Vocational Psychology",
      psychogerontology: "Psychogerontology",
      phenomenologicalDreamAnalysis: "Phenomenological Dream Analysis",
    },
  },
  about: {
    title: "About us",
    description:
      "Psy Algarve aims to provide Clinical Psychology and Psychotherapy services of great technical and human quality. We strive to improve your well-being and happiness. In order to adapt to your needs we offer a wide range of Psychology services, by a team of competent psychologists specialised in different approaches.\n\nWe are present in Algarve and we provide services in face-to-face or online to the whole world. We also provide you home therapy and Therapy in different languages.",
  },
  team: {
    title: "Meet The Team",
    registrationNumber: "Professional registry number: {n}",
    member: {
      susanaMartins: {
        role: "Bachelor's Degree in Clinical Psychology",
        description:
          "Graduated in Clinical Psychology and working since 2010 in several Clinics and Institutions, such as CAFAP, RSI Protocol Psychologist, with her most recent professional experience being as Technical Director of the Residential Structure for the Elderly (ERPI). Postgraduate degree in Human Resource Management: HR for People Managers from the University of Minnesota. Professional Training Course in Management of Social Economy Organizations. Training in Behavioral Therapies from ISPA. Advanced Specialization in Dementia: from assessment to intervention.",
      },
      mariaNeves: {
        role: "PhD in Contemporary Philosophy",
        description:
          "Maria João Neves holds a PhD in Contemporary Philosophy and is a researcher at the Universidade Nova de Lisboa. She wrote the book Método RVP© (Raciovitalismo Poético), published by the Instituto Piaget in 2009, where she presents her method of philosophical counseling, which includes the Phenomenological Analysis of Dreams. She writes regularly for scientific journals. To see her publications, search for Maria João Neves at academia.edu. Since 2015, she has written the monthly column Consultório Filosófico for the newspaper Cultura.Sul/Postal do Algarve and organizes Cafés Filosóficos in Portuguese and English.",
      },
      brunoMartins: {
        role: "Bachelor's Degree in Psychology and Master's Degree in Clinical Psychology",
        description:
          "Currently working in the areas of Clinical Psychology and Psycho-Oncology, in his private practice at Triumcare, lda. He completed his internship within a therapeutic rehabilitation community for drug addicts and other addictions (alcohol, drugs, gambling, etc.). He completed his internship to access the Portuguese Order of Psychologists at the Portuguese League Against Cancer – NRN, and has remained a volunteer Psychologist to this day, gaining extensive experience in Psycho-Oncology. He worked as a Psychologist for 6 years at the Laços e Sonhos P’ra Vida Association (Vila Real), an association that supports cancer patients in general and breast cancer patients in particular.",
      },
      suziRodrigues: {
        role: "PhD in Psychology",
        description:
          "Suzi Rodrigues is a PhD candidate in Psychology at the Faculty of Social Sciences and Humanities of the University of Algarve. Her professional experience lies in psychopedagogical and community intervention aimed at children, young people and families with psychosocial risk factors, career (re)orientation and counselling, consultancy and training. Regarding her research work, her interests lie in the field of vocational psychology, particularly in support contexts, and in this area she has published articles and given, by invitation, oral communications at national and international conferences. She represented Portugal at the Euroguidance Cross Border Seminar Belgrade: Innovative Practices for new Skills by presenting a workshop on the Programme for the Promotion of Parental Support and Career Development. She was invited to participate in the Webinar promoted by the General Directorate of Education: Promotion of Parental Support and Career Development in 8th Grade Students. She is currently a full member of the Portuguese Order of Psychologists (OPP), Manager of the International Project MILAGE Aprender+, is part of the coordination and research team of the Gulbenkian Academy of Knowledge, EPARCA, is part of the team of the Project “Escola Aprender+: ambientees educativos inovaçãos” (School Learn+: innovative educational environments), guest assistant at the Faculty of Human and Social Sciences at the University of Algarve and collaborates in the psychology service of the University of Algarve, in the Unit of Psychology of Education, Development and Vocational Counseling as a psychologist.",
      },
    },
  },
  contact: {
    info: {
      title: "Contact Us",
      description:
        "Our team of experts is available to answer your questions regarding appointments and follow-up, during weekdays between 09:00h and 18:00h",
    },
    form: {
      title: "Book Your Appointment",
      btn: "Book Apointment",
      success: {
        title: "Message sent",
        description:
          "Thank you for your message. We will contact you as soon as possible regarding your appointment.",
      },
      error: {
        title: "Message not sent",
        description:
          "An error ocurred while sending your message. If the issue persists, please contact us using alternative methods.",
        btn: "Retry sending message",
      },
    },
  },
  footer: {
    copyright: "Copyright © 2024 Psy Algarve | All rights reserved",
  },
  input: {
    name: {
      placeholder: "First and Last Name",
    },
    region: {
      placeholder: "Region",
    },
    age: {
      placeholder: "Age",
    },
    phone: {
      placeholder: "Mobile Number",
    },
    email: {
      placeholder: "E-mail",
    },
    time: {
      placeholder: "Hour Preference",
    },
    service: {
      placeholder: "Psychology Area",
    },
    message: {
      placeholder: "Additional information or details",
    },
    error: {
      required: "Required field",
    },
  },
};
