<template>
  <div class="contact" id="contact">
    <LayoutContainer class="contact-container">
      <div class="info-container">
        <div class="text-container">
          <h2>{{ $t("contact.info.title") }}</h2>
          <p>{{ $t("contact.info.description") }}</p>
        </div>

        <div class="info-list">
          <div class="info">
            <div class="icon-wrapper">
              <Icon icon="mdi:phone" />
            </div>
            <div>(+351) 964 931 313</div>
          </div>
          <div class="info">
            <div class="icon-wrapper">
              <Icon icon="mdi:email" />
            </div>
            <div>geral.psyalgarve@gmail.com</div>
          </div>
        </div>
      </div>
      <div class="form-container">
        <Transition name="fade" mode="out-in">
          <div class="loading-container" v-if="loading">
            <div class="spinner" />
          </div>
          <div
            class="success-container"
            v-else-if="result?.status == 'success'"
          >
            <h2>{{ $t(`contact.form.success.title`) }}</h2>
            <p>{{ $t(`contact.form.success.description`) }}</p>
          </div>
          <div class="error-container" v-else-if="result?.status == 'error'">
            <h2>{{ $t(`contact.form.error.title`) }}</h2>
            <p>{{ $t(`contact.form.error.description`) }}</p>
            <Button color="white" @click="result = undefined">{{
              $t("contact.form.error.btn")
            }}</Button>
          </div>
          <div id="form" v-else>
            <h2>{{ $t("contact.form.title") }}</h2>
            <FormKit type="form" @submit="submit" :key="$i18n.locale">
              <FormKit
                name="fullName"
                outerClass="input-name"
                type="text"
                :placeholder="$t('input.name.placeholder')"
                validation="required"
                :validationMessages="{
                  required: $t('input.error.required'),
                }"
              />
              <FormKit
                name="region"
                type="select"
                :placeholder="$t('input.region.placeholder')"
                :options="regionOptions"
                validation="required"
                :validationMessages="{
                  required: $t('input.error.required'),
                }"
              />
              <FormKit
                name="age"
                type="number"
                :placeholder="$t('input.age.placeholder')"
                validation="required"
                :validationMessages="{
                  required: $t('input.error.required'),
                }"
              />
              <FormKit
                name="phone"
                outerClass="input-phone"
                type="tel"
                :placeholder="$t('input.phone.placeholder')"
                validation="required"
                :validationMessages="{
                  required: $t('input.error.required'),
                }"
              />
              <FormKit
                name="email"
                outerClass="input-email"
                type="email"
                :placeholder="$t('input.email.placeholder')"
                validation="required"
                :validationMessages="{
                  required: $t('input.error.required'),
                }"
              />
              <FormKit
                name="time"
                outerClass="input-time"
                type="select"
                :placeholder="$t('input.time.placeholder')"
                :options="timeOptions"
                validation="required"
                :validationMessages="{
                  required: $t('input.error.required'),
                }"
              />
              <FormKit
                name="service"
                outerClass="input-service"
                type="select"
                :placeholder="$t('input.service.placeholder')"
                :options="serviceOptions"
                validation="required"
                :validationMessages="{
                  required: $t('input.error.required'),
                }"
              />
              <FormKit
                name="message"
                outerClass="input-message"
                type="textarea"
                :placeholder="$t('input.message.placeholder')"
                :validationMessages="{
                  required: $t('input.error.required'),
                }"
              />
              <template #submit>
                <Button color="white" type="submit">{{
                  $t("contact.form.btn")
                }}</Button>
              </template>
            </FormKit>
          </div>
        </Transition>
      </div>
    </LayoutContainer>
  </div>
</template>

<script lang="ts" setup>
import { Icon } from "@iconify/vue";
import Button from "./Button.vue";
import LayoutContainer from "./LayoutContainer.vue";
import { useI18n } from "vue-i18n";
import { Ref, computed } from "vue";
import { sendEmail } from "@/firebase";
import { ref } from "vue";
import { services } from "@/data";

const { t, messages } = useI18n();

const regionOptions = [
  "Tavira",
  "Olhão",
  "Faro",
  "São Brás de Alportel",
  "Loulé",
  "Albufeira",
];
const timeOptions = (() => {
  const timeOptions: string[] = [];

  for (let i = 9; i <= 18; i++) {
    timeOptions.push(`${i}:00`);
  }

  return timeOptions;
})();
const serviceOptions: Ref<{ value: string; label: string }[]> = computed(() =>
  services.map((s) => ({
    value: messages.value.pt.services["list"][s],
    label: t(`services.list.${s}`),
  }))
);

const loading = ref(false);
const result: Ref<{ status: "success" | "error"; error?: any } | undefined> =
  ref();

const submit = (e: {
  fullName: string;
  email: string;
  region: string;
  age: string;
  time: string;
  service: string;
  message: string;
}) => {
  loading.value = true;

  sendEmail({
    fullName: e.fullName,
    email: e.email,
    region: e.region,
    age: e.age,
    time: e.time,
    service: e.service,
    message: e.message,
  }).then((resp: any) => {
    result.value = resp.data;
    loading.value = false;

    if (resp.data.error) {
      console.log(resp.data.error);
    }
  });
};
</script>

<style lang="scss" scoped>
.contact {
  @media screen and (min-width: $break-lg-min) {
    background: $color-beige;
    margin-top: 70px;
  }

  .contact-container {
    box-shadow: $box-shadow-dark;

    @media screen and (min-width: $break-lg-min) {
      position: relative;
      top: -70px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 100px;
      border-radius: 32px;
      padding: 16px 16px 16px 48px;
      background-color: $color-white;
    }

    .info-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 0;

      .text-container {
        h2 {
          font-size: 24px;
          margin: 0 0 15px;

          @media screen and (min-width: $break-lg-min) {
            font-size: 32px;
            margin: 0 0 24px;
          }
        }

        p {
          font-size: 14px;
          margin: 0 0 20px;

          @media screen and (min-width: $break-lg-min) {
            font-size: 16px;
          }
        }
      }

      .info-list {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media screen and (min-width: $break-lg-min) {
          gap: 20px;
        }

        .info {
          display: grid;
          grid-template-columns: 30px 1fr;
          align-items: center;
          gap: 10px;

          @media screen and (min-width: $break-lg-min) {
            grid-template-columns: 40px 1fr;
            gap: 20px;
          }

          .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background-color: $color-teal;
            color: $color-white;
            height: 30px;
            font-size: 18px;

            @media screen and (min-width: $break-lg-min) {
              height: 40px;
              font-size: 24px;
            }
          }
        }
      }
    }

    .form-container {
      background-color: $color-teal;
      padding: 20px;
      margin: 0 -20px;

      @media screen and (min-width: $break-md-min) {
        margin: 0 -30px;
        padding: 30px;
      }

      @media screen and (min-width: $break-lg-min) {
        margin: 0;
        padding: 30px 45px;
        border-radius: 16px;
      }

      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.3s ease-in-out;
      }

      .fade-enter-to,
      .fade-leave-from {
        opacity: 1;
      }

      .fade-enter-from,
      .fade-leave-to {
        opacity: 0;
      }

      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .spinner {
          border: 7px solid $color-dark-white;
          border-top-color: $color-white;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          animation: spin 1s infinite;
          animation-timing-function: linear;
        }
      }

      h2 {
        font-size: 24px;
        margin: 0 0 24px;
        color: $color-white;

        @media screen and (min-width: $break-lg-min) {
          font-size: 32px;
        }
      }

      p {
        font-size: 18px;
        color: $color-white;
      }

      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

        @media screen and (min-width: $break-lg-min) {
          grid-template-columns: 2fr 1fr 1fr;
        }

        .input-name,
        .input-phone,
        .input-time {
          @media screen and (max-width: $break-md-max) {
            grid-column: span 2;
          }
        }

        .input-email,
        .input-service {
          grid-column: span 2;
        }

        .input-message {
          grid-column: span 2;

          @media screen and (min-width: $break-lg-min) {
            grid-column: span 3;
          }
        }

        :deep(.formkit-actions) {
          justify-self: center;
          grid-column: span 2;

          @media screen and (min-width: $break-md-min) {
            justify-self: flex-start;
          }

          @media screen and (min-width: $break-lg-min) {
            grid-column: span 3;
          }
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
