<template>
  <div class="services" id="services">
    <LayoutContainer class="services-container">
      <h2>{{ $t("services.title") }}</h2>
      <div class="blaze-slider" ref="slider">
        <div class="blaze-container">
          <button class="blaze-prev">
            <Icon icon="mdi:arrow-left" />
          </button>
          <div class="blaze-track-container">
            <div class="blaze-track">
              <div class="service" v-for="service in services">
                <img src="@/assets/images/service_1.svg" />
                <div class="title">
                  {{ $t(`services.list.${service}`) }}
                </div>
              </div>
            </div>
          </div>
          <button class="blaze-next">
            <Icon icon="mdi:arrow-right" />
          </button>
          <div class="blaze-pagination" />
        </div>
      </div>
    </LayoutContainer>
  </div>
</template>

<script lang="ts" setup>
import LayoutContainer from "./LayoutContainer.vue";
import { onMounted, ref } from "vue";
import BlazeSlider from "blaze-slider";
import { Icon } from "@iconify/vue";
import { services } from "@/data";

const slider = ref();

onMounted(() => {
  new BlazeSlider(slider.value, {
    all: {
      slidesToScroll: 1,
      slidesToShow: 1,
    },
    "(min-width: 480px)": {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
    "(min-width: 1024px)": {
      slidesToScroll: 3,
      slidesToShow: 3,
    },
    "(min-width: 1200px)": {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  });
});
</script>

<style lang="scss" scoped>
.services {
  background: $color-beige;
  padding-top: 40px;
  padding-bottom: 40px;

  h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 35px;
  }

  .blaze-container {
    display: grid;
    align-items: center;
    grid-template-rows: repeat(2, auto);
    gap: 20px;

    @media screen and (min-width: $break-lg-min) {
      grid-template-columns: 32px 1fr 32px;
    }

    .blaze-prev,
    .blaze-next {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: 2px solid $color-dark-grey;
      border-radius: 50%;
      font-size: 16px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: $break-md-max) {
        display: none;
      }

      &:hover {
        background-color: $color-dark-grey;
        color: $color-white;
      }
    }

    .blaze-pagination {
      display: flex;
      justify-content: center;
      gap: 8px;

      @media screen and (min-width: $break-lg-min) {
        grid-column: 1 / 3;
      }

      :deep(button) {
        display: block;
        font-size: 0;
        width: 12px;
        height: 12px;
        background-color: $color-dark-grey;
        border-radius: 50%;
        border: none;
        padding: 0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &.active,
        &:hover {
          background-color: $color-dark-teal;
        }
      }
    }
  }

  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 20px 10px 10px;
    background: $color-white;
    border-radius: 24px;

    img {
      display: block;
      max-width: 45px;
    }

    .title {
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      text-align: center;
    }
  }
}
</style>
