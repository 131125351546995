import { createApp } from "vue";
import App from "./App.vue";

import { VueFire, VueFireAuth } from "vuefire";
import { firebaseApp } from "./firebase";
import { createI18n } from "vue-i18n";
import { plugin, defaultConfig } from "@formkit/vue";
import config from "./formkit/formkit.config";

import messages from "./lang";

import "blaze-slider/dist/blaze.css";
import "@formkit/themes/genesis";
import "./assets/styles/main.scss";

const app = createApp(App);

app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()],
});

app.use(plugin, defaultConfig(config));

app.use(
  createI18n({
    legacy: false,
    locale: navigator.language?.split("-")?.[0] || "en",
    fallbackLocale: "en",
    messages,
  })
);

app.mount("#app");
