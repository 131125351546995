<template>
  <div class="layout-container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.layout-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $break-md-min) {
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
</style>
