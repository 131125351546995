export const services = [
  "clinicalPsychology",
  "individualTherapy",
  "homeTherapy",
  "onlineTherapy",
  "psychotherapy",
  "mindfulness",
  "vocationalPsychology",
  "psychogerontology",
  "phenomenologicalDreamAnalysis",
];
