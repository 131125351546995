export const smoothScroll = (id: string) => {
  const target = document.querySelector(id) as HTMLElement;
  const headerHeight = (document.querySelector("#page-header") as HTMLElement)
    ?.offsetHeight;

  if (target) {
    window.scrollTo({
      top: target.offsetTop - headerHeight,
      behavior: "smooth",
    });
  }
};
