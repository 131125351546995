export default {
  header: {
    links: {
      services: "Serviços",
      about: "Sobre nós",
      contact: "Contacto",
    },
  },
  hero: {
    title: {
      text: "{0} não é um destino mas um {1}.",
      bold: "Saúde Mental",
      teal: "processo",
    },
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquet varius accumsan. Donec aliquam tempor cursus. Fusce non interdum augue, ac semper risus. Pellentesque quis nisl in lectus ultricies.",
    btn: {
      services: "Os nossos Serviços",
      contact: "Contacte-nos",
    },
  },
  services: {
    title: "Os Nossos Serviços",
    list: {
      clinicalPsychology: "Psicologia Clínica",
      individualTherapy: "Terapia Individual",
      homeTherapy: "Terapia no Domicílio",
      onlineTherapy: "Terapia Online",
      psychotherapy: "Psicoterapia",
      mindfulness: "Mindfulness",
      vocationalPsychology: "Psicologia Vocacional",
      psychogerontology: "Psicogerontologia",
      phenomenologicalDreamAnalysis: "Análise Fenomenológica de Sonhos",
    },
  },
  about: {
    title: "Sobre nós",
    description:
      "A Psy Algarve providencia serviços de Psicologia Clínica e de Psicoterapia de elevada qualidade técnica e humana. Nós esforçamo-nos para melhorar o seu bem-estar e felicidade. Oferecemos uma variedade de serviços de Psicologia de maneira a adaptar-nos às suas necessidades, com uma equipa de pssicólogos competentes especializados em diversas abordagens.\n\nEstamos presentes no Algarve e providenciamos serviços em pessoa ou online para todo o mundo. também providenciamos terapia no domicílio e terapia em várias línguas.",
  },
  team: {
    title: "Conheça a equipa",
    registrationNumber: "Cédula profissional: {n}",
    member: {
      susanaMartins: {
        role: "Licenciatura em Psicologia Clínica",
        description:
          "Licenciada em Psicologia Clínica e a exercer desde 2010 em várias Clínicas e Instituições, tais como CAFAP, Psicóloga do Protocolo RSI, sendo que a última experiência profissional foi de Diretora Técnica da Estrutura Residencial para Pessoas Idosas (ERPI). Pós Graduação em Human Resource Management: HR for People Managers pela Universidade de Minnesota. Curso de Formação Profissional de Gestão de Organizações da Economia Social. Formação em Terapias Comportamentais pelo ISPA. Especialização Avançada em Demências: da avaliação à intervenção.",
      },
      mariaNeves: {
        role: "Doutoramento em Filosofia Contemporânea",
        description:
          "Maria João Neves é doutorada em Filosofia Contemporânea e é investigadora da Universidade Nova de Lisboa. Escreveu o livro Método RVP© (Raciovitalismo Poético), publicado pelo Instituto Piaget em 2009 onde expõe o seu método de aconselhamento filosófico que inclui a Análise Fenomenológica de Sonhos. Escreve regularmente para revistas científicas. Para ver as publicações procure Maria João Neves em academia.edu. Desde 2015 escreve mensalmente para o jornal Cultura.Sul/ Postal do Algarve a coluna Consultório Filosófico e organiza Cafés Filosóficos em Português e Inglês.",
      },
      brunoMartins: {
        role: "Licenciado em Psicologia e Mestre em Psicologia Clínica",
        description:
          "Atualmente exerce funções na área da Psicologia Clínica e da Psico-Oncologia, no seu consultório privado na Triumcare, lda. Realizou o estágio curricular no seio de uma comunidade terapêutica de reabilitação de toxicodependentes e outras adições (álcool, fármacos, jogo etc.). O estágio de acesso à Ordem dos Psicólogos Portugueses foi efetuado na Liga Portuguesa Contra o Cancro – NRN, permanecendo até aos dias de hoje como Psicólogo voluntário, adquirindo uma larga experiência em Psico-Oncologia. Foi Psicólogo, durante 6 anos, na Associação Laços e Sonhos P’ra vida (Vila Real), associação de apoio a doentes oncológicos no geral e com cancro da mama, em particular.",
      },
      suziRodrigues: {
        role: "Doutoranda em Psicologia",
        description:
          "Suzi Rodrigues é doutoranda em Psicologia pela Faculdade de Ciências Sociais e Humanas da Universidade do Algarve. A sua experiência profissional situa-se na intervenção psicopedagógica e comunitária destinada a crianças, jovens e famílias que apresentam fatores de risco psicossocial, (re)orientação e aconselhamento de carreira, assessoria e formação. No que se refere ao trabalho de investigação os seus interesses situam-se no domínio da psicologia vocacional, em particular nos contextos de suporte, sendo que neste âmbito, publicou artigos e realizou, por convite, comunicações orais em congressos nacionais e internacionais. Representou Portugal, no Euroguidance Cross Border Seminar Belgrade: Innovative Practices for new Skills através da apresentação em workshop do Programa de Promoção do Suporte Parental e Desenvolvimento de Carreira. Foi convidada a participar no Webinar promovido pela Direção Geral de Educação: Promoção do Suporte Parental e Desenvolvimento de Carreira em alunos do 8.º Ano de Escolaridade. Atualmente é membro efetivo da Ordem dos Psicólogos Portugueses (OPP), Gestora do Projeto Internacional MILAGE Aprender+, integra a equipa de coordenação e investigação da Academia Gulbenkian do Conhecimento, EPARCA, faz parte da equipa do Projeto “Escola Aprender+: ambientes educativos inovadores”, assistente convidada na Faculdade de Ciências Humanas e Socias na Universidade do Algarve e colabora no serviço de psicologia da Universidade do Algarve, na Unidade de Psicologia da Educação, Desenvolvimento e Aconselhamento Vocacional na qualidade de psicóloga.",
      },
    },
  },
  contact: {
    info: {
      title: "Contacte-nos",
      description:
        "A nossa equipa de especialistas está disponível para responder às suas perguntas no que toca a agendamento e acompanhamento, durante os dias úteis das 09:00h até às 18:00h",
    },
    form: {
      title: "Marque a sua consulta",
      btn: "Marcar consulta",
      success: {
        title: "Mensagem enviada",
        description:
          "Obrigado pela sua mensagem. Entraremos em contacto o mais breve possível para agendar a sua consulta.",
      },
      error: {
        title: "Mensagem não enviada",
        description:
          "Ocorreu um erro ao enviar a mensagem. Se o problema persistir, por favor utilize os meios alternativos.",
        btn: "Reenviar",
      },
    },
  },
  footer: {
    copyright: "Copyright © 2024 Psy Algarve | Todos os direitos reservados",
  },
  input: {
    name: {
      placeholder: "Primeiro e último nome",
    },
    region: {
      placeholder: "Região",
    },
    age: {
      placeholder: "Idade",
    },
    phone: {
      placeholder: "Número Telemóvel",
    },
    email: {
      placeholder: "E-mail",
    },
    time: {
      placeholder: "Preferência horária",
    },
    service: {
      placeholder: "Área de psicologia",
    },
    message: {
      placeholder: "Informação adicional ou detalhes",
    },
    error: {
      required: "Campo obrigatório",
    },
  },
};
