<template>
  <div class="team">
    <LayoutContainer class="team-container">
      <div class="text-container">
        <h2>{{ $t("team.title") }}</h2>

        <div class="member-info-container">
          <TransitionGroup name="fade">
            <div
              class="member-info"
              v-for="(member, i) in members"
              :key="i"
              v-show="i == activeMember"
            >
              <h3>{{ member.name }}</h3>
              <h4>{{ $t(`team.member.${member.key}.role`) }}</h4>
              <p class="registration-number" v-if="member.id">
                {{ $t("team.registrationNumber", member.id) }}
              </p>
              <div class="member-description">
                <p>{{ $t(`team.member.${member.key}.description`) }}</p>
              </div>
            </div>
          </TransitionGroup>
        </div>

        <div class="controls-container">
          <button @click="previousMember">
            <Icon icon="mdi:arrow-left" />
          </button>
          <button @click="nextMember">
            <Icon icon="mdi:arrow-right" />
          </button>
        </div>
      </div>
      <div class="image-container">
        <TransitionGroup name="fade">
          <img
            v-for="(member, i) in members"
            :src="require(`@/assets/images/member_${i}.webp`)"
            :key="i"
            v-show="i == activeMember"
          />
        </TransitionGroup>
      </div>
    </LayoutContainer>
  </div>
</template>

<script lang="ts" setup>
import { Ref, ref } from "vue";
import { Icon } from "@iconify/vue";
import LayoutContainer from "./LayoutContainer.vue";

const activeMember = ref(0);

const members: Ref<
  {
    name: string;
    key: string;
    id?: number;
  }[]
> = ref([
  {
    name: "Susana Martins",
    id: 15989,
    key: "susanaMartins",
  },
  {
    name: "Maria João Neves Ph.D",
    key: "mariaNeves",
  },
  {
    name: "Dr Bruno Martins",
    id: 20591,
    key: "brunoMartins",
  },
  {
    name: "Suzi Rodrigues",
    key: "suziRodrigues",
  },
]);

const nextMember = () => {
  activeMember.value =
    activeMember.value + 1 >= members.value.length ? 0 : activeMember.value + 1;
};
const previousMember = () => {
  activeMember.value =
    activeMember.value - 1 < 0
      ? members.value.length - 1
      : activeMember.value - 1;
};
</script>

<style lang="scss" scoped>
.team {
  padding-top: 20px;
  padding-bottom: 80px;

  .team-container {
    @include tile-grid;
  }

  .text-container {
    h2 {
      font-size: 32px;
      line-height: 40px;
      margin: 0 0 40px;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 8px;
      color: $color-teal;
    }

    h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin: 0 0 16px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin: 0;

      &.registration-number {
        font-weight: 600;
        font-size: 14px;
        margin: 0 0 16px;
      }
    }

    .member-description {
      overflow-y: auto;
      max-height: 220px;
      padding-right: 15px;
      margin: 0 0 16px;
    }
  }

  .member-info-container {
    @include transition-fade-group;
  }

  .controls-container {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 2px solid $color-dark-grey;
      border-radius: 50%;
      background: none;
      font-size: 24px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: $color-dark-grey;
        color: $color-white;
      }
    }
  }

  .image-container {
    overflow: hidden;
    border-radius: 32px 0px 32px 32px;
    @include transition-fade-group;
    max-width: 400px;
    justify-self: center;

    img {
      display: block;
      width: 100%;
      border-radius: 32px 0px 32px 32px;
    }
  }
}
</style>
